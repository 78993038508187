import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames } from '@shapeable/utils';
import { SliceLayoutBoundary, SiteHeaderLayout, ExplorerEntityDetailsLayoutDefaultProps, ExplorerEntityDetailsLayout, ComponentPropsProvider, ExplorerEntityDetailsLayoutProps, BackButtonEntityProvider, SiteHeaderProvider, ExplorerTabs, } from '@shapeable/ui';
import { ExplorerProvider } from '../providers/explorer-provider';
import { SubTopic, Topic } from '@shapeable/copper-connect-types';
import { ValueChainNavigator } from '../elements/value-chain-navigator';
import { useValueChainMap } from '../../hooks/use-value-chain-map';
import { ValueChainNavigatorMain } from '../elements/value-chain-navigator-main';
import { TopicTabsLayout } from './topic-tabs-layout';
import { TopicBannerLayout } from './topic-banner-layout';
import { TopicViewLayout } from './topic-view-layout';
import { SubTopicTabsLayout } from './sub-topic-tabs-layout';
import { SubTopicViewLayout } from './sub-topic-view-layout';
const cls = classNames('sub-topic-explorer-layout');

// -------- Types -------->

// -------- Props -------->

export type SubTopicExplorerLayoutProps = ExplorerEntityDetailsLayoutProps & { 
  entity?: SubTopic;
};

export const SubTopicExplorerLayoutDefaultProps: Omit<SubTopicExplorerLayoutProps, 'entity'> = {
  ...ExplorerEntityDetailsLayoutDefaultProps,
};

// -------- Child Component Props -------->

type ContainerProps = {

};

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  
  `,
});

const LayoutStyles = breakpoints({
  base: css`
    padding-top: ${theme.UNIT(4)};

    .shp--entity-banner {
      h2.shp--entity-value__name {
        font-size: ${theme.FONT_SIZE(32)};
      }
      h2.shp--cell-label {
        color: ${theme.COLOR('light')};
      }
    }
  `,
});

const HeaderStyles = breakpoints({
  base: css`
    margin-bottom: ${theme.UNIT(4)};
  `,
});

const NavigatorStyles = breakpoints({
  base: css`
  `,
});


const TabsStyles = breakpoints({
  base: css`
  `,
});


// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Header: styled(SiteHeaderLayout)`${HeaderStyles}`,
    Layout: styled(ExplorerEntityDetailsLayout)`${LayoutStyles}`,
      Navigator: styled(ValueChainNavigator)`${NavigatorStyles}`,

      Tabs: styled(ExplorerTabs)`${TabsStyles}`,

};

export const SubTopicExplorerLayout: Shapeable.FC<SubTopicExplorerLayoutProps> = (props) => {
  const { className, children, entity } = props;
  const { isShown } = useValueChainMap();

  return (
    <SiteHeaderProvider value={{ 
      variant: 'default',
    }}>
    <ComponentPropsProvider value={{
      EntityBanner: {
        maxHeight: 300,
        variant: 'color',
        customAspectRatio: { base: 16 / 9, desktop: 21 / 9 },
        entityValueProps: { showImage: false, showOutlineNumber: true, outlineBubble: { numberSize: 0.6, showBorder: true, showImage: false }},
      },
    }}>
    <ExplorerProvider>
    <My.Container>
      <My.Header/>
      <SliceLayoutBoundary boundary='none' >
      <My.Tabs useStickyStyle />
      <My.Layout 
        entity={entity}
        className={cls.name(className)}
        asideIsRevealed={isShown}
        stickyTopOffset={117}

        //main
        tabs={
          <SubTopicTabsLayout entity={entity} />
        }
        content={
          <SubTopicViewLayout entity={entity} />
        }

        dataVizMain={
          <ValueChainNavigatorMain /> 
        }

        dataViz={
          <My.Navigator />
        }
        />
        </SliceLayoutBoundary>
    </My.Container>
    </ExplorerProvider>
    </ComponentPropsProvider>
    </SiteHeaderProvider>
  )
};

SubTopicExplorerLayout.defaultProps = SubTopicExplorerLayoutDefaultProps;
SubTopicExplorerLayout.cls = cls;